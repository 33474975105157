import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const options = {
  icons: {
    iconfont: "md",
  },
  theme: {
    themes: {
      light: {
        primary: "#0076C4",
        secondary: "#0076C4",
        accent: "#0076C4",
        error: "#b71c1c",
        warning: "#e0a500",
        grey: "#9E9E9E"
      },
    },
  },
};

export default new Vuetify(options);
