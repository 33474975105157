import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import VueMeta from "vue-meta";

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.config.productionTip = false;

let app = "";

if (!app) {
  app = new Vue({
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
}
